import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Doing More Town Halls`}</h1>
    <p>{`One fantastic idea I saw implemented this year by Student Forum was the IHS Townhall. Kudos to the Irvine High Student Forum officer team for putting that together in a smooth and helpful manner! If you were able to go, I'm sure you say the long list of suggestions students had and also shared some of your own thoughts. If you didn't go, this is how it went:`}</p>
    <ul>
      <li parentName="ul">{`There were three topics:`}
        <ul parentName="li">
          <li parentName="ul">{`Safety Protocols`}</li>
          <li parentName="ul">{`Student Wellness`}</li>
          <li parentName="ul">{`Academics: IVA/Hybrid Models`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Everybody joined a collaborative `}<a parentName="li" {...{
          "href": "https://padlet.com/tracygallo1/wzp83u6nlwmnsqu"
        }}>{`Padlet`}</a>{` and entered in their ideas`}
        <ul parentName="li">
          <li parentName="ul">{`As you added your ideas, you could see other people type theirs in and comment on them`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Overall, I thought the entire event was successful. They had almost 75 members in attendance, there were great ideas on the Padlet, and the principal was in attendance! I wish, though, that we were given a chance to justify our suggestions and discuss them live with everyone else. If not that, then possibly liking the ones that were relevant to us, in order to prioritize which suggestions to implement. `}</p>
    <p>{`There are definitely many factors that play into an event like this, and for the first townhall in my 3 years of high school, I am glad it happened. Next year, I want to do more. I think it would be beneficial to do a townhall on issues throughout the year, perhaps every month or progress report time! `}</p>
    <p>{`All of the ideas listed were pseudonymous, which also let students feel better about being harsh in their ideas and thinking critically about what they actually felt. Whether in person or virtual, this is something that I liked and would like to continue for next year.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      